<template>
  <div class="navRight">
    <div class="navRight_header flex-sb">
      <div class="title flex">
        <p style="font-size: 1.25rem">{{ t('161') }}</p>
      </div>
      <div class="flex-sb" style="height: 100%">
        <div class="icon_btn blue_btn" @click="exportTaskRecord()">
          <el-icon class="element_icon"> <Upload /> </el-icon>&nbsp;{{ t('647') }}
        </div>
        <div class="icon_btn red_btn" style="margin-top: 2px" @click="batchDel">
          <el-icon class="element_icon"> <Delete /> </el-icon>&nbsp;{{ t('210') }}
        </div>
      </div>
    </div>
    <div class="navRight_footer">
      <search-bar
        class="search"
        :searchPlaceholder="t('794')"
        width="19.625rem"
        v-model:searchContent="state.queryForm.keyword"
        @on-search="getData(true)"
      />
      <ele-select
        :label="t('795')"
        :options="state.statusList"
        :labelname="'desc'"
        :value="'value'"
        v-model:selectVal="state.queryForm.taskStatus"
        v-model:selectLable="state.queryForm.taskValue"
        @on-select="getData(true)"
      />
      <ele-select
        :label="t('796')"
        :options="memberList"
        :labelname="'username'"
        :value="'id'"
        v-model:selectVal="memberList.id"
        @on-select="getData(true)"
      />
      <select v-model="choose" class="selection">
        <option class="item" value="AIRPORT">{{ t('164') }}</option>
        <option class="item" value="UAV">{{ t('165') }}</option>
      </select>
      <ele-select
        :lableshow="false"
        :options="deviceList"
        :labelname="'deviceName'"
        :value="'sn'"
        v-model:selectVal="deviceList.sn"
        v-model:selectLable="deviceList.deviceName"
        @on-select="getData(true)"
      ></ele-select>
      <ele-select
        :label="t('797')"
        :options="taskSelectList"
        :labelname="'taskName'"
        :value="'id'"
        v-model:selectVal="taskSelectList.id"
        @on-select="getData(true)"
      />
    </div>
  </div>
  <div class="container">
    <ele-table
      :tableColumnArray="tableColumnArray"
      :tableData="state.tableData"
      v-model:pageNo="state.queryForm.pageNo"
      v-model:pageSize="state.queryForm.pageSize"
      :total="state.queryForm.total"
      @handleNoChange="getData"
      @handleSizeChange="getData"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:buttonHanlder>
        <el-table-column :label="t('610')" align="center">
          <template v-slot="{ row }">
            <el-row>
              <el-col :span="8">
                <span class="blue_btn" @click="jumpExecRecord(row.recordId)"
                  >{{ t('162') }}</span
                >
              </el-col>
              <el-col :span="8">
                <span class="blue_btn" @click="picDetail(row.recordId)"
                  >{{ t('391') }}</span
                >
              </el-col>
              <el-col :span="8">
                <span class="blue_btn" @click="openRecordRemark(row)"
                  >{{ t('413') }}</span
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </template>
    </ele-table>
  </div>
  <picturedetail
    v-if="state.isPicdetail"
    v-model:isVisible="state.isPicdetail"
    :picRecordId="state.picRecordId"
  ></picturedetail>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('413')"
    :dialogWidth="'28.5rem'"
    :hasFooter="false"
    @on-cancel="isVisible = false"
  >
    <h4 style="padding-bottom: 5rem">{{ remark }}</h4>
  </ele-dialog>
</template>

<script setup>
import { t } from '../../languages';
import { useRoute, useRouter } from "vue-router";
import { reactive, ref, watchEffect } from "vue";
import taskManage from "@/network/task";
import { ElMessage, ElMessageBox } from "element-plus";
import download from "@/network/download";
import picturedetail from "../../components/page/data-manage/components/picture-detail.vue";
const route = useRoute();

//表头名称
const tableColumnArray = [
  { label: t("798"), prop: "recordId", width: "125%" },
  { label: t("399"), prop: "taskName", width: "135%" },
  { label: t("423"), prop: "username", width: "100%" },
  { label: t("799"), prop: "execTime" },
  { label: t("800"), prop: "endTime" },
  { label: t("385"), prop: "taskStatus", width: "125%" },
  { label: t("234"), prop: "photoNumber", width: "100%" },
];

const state = reactive({
  isAirport: "",
  tableData: [],
  queryForm: {
    keyword: "",
    pageNo: 1,
    pageSize: 20,
    total: 0,
    taskStatus: "",
    taskValue: "",
  },
  statusList: [],
  picRecordId: "",
  isPicdetail: false,
});

// 获取下发人下拉菜单
const memberList = ref([]);
const getMemberList = async () => {
  const res = await taskManage.getMemberList({});
  memberList.value = res.resultData;
};
getMemberList();

/* 获取所有任务下拉菜单 */
const taskSelectList = ref([]);
async function getTaskSelectList() {
  const res = await taskManage.getTaskSelectList();
  taskSelectList.value = res.resultData;
}
getTaskSelectList();

const deviceList = ref([]);
const choose = ref("AIRPORT");
const getDeviceList = async () => {
  const res = await taskManage.getDeviceList({
    deviceType: state.isAirport,
  });
  deviceList.value = res.resultData;
};
watchEffect(() => {
  state.isAirport = choose.value;
  getDeviceList();
});

async function getData(flag) {
  // 重置页数
  if (flag) {
    state.queryForm.pageNo = 1;
  }
  if (deviceList.value.sn === undefined) {
    deviceList.value.sn = "";
  }
  if (taskSelectList.value.id === undefined) {
    taskSelectList.value.id = "";
  }
  const res = await taskManage.getTaskRecord({
    keyword: state.queryForm.keyword,
    pageNo: state.queryForm.pageNo,
    pageSize: state.queryForm.pageSize,
    taskStatus: state.queryForm.taskStatus,
    userId: memberList.value.id,
    sn: deviceList.value.sn,
    taskId: taskSelectList.value.id,
  });
  if (res.resultStatus) {
    state.tableData = res.resultData.data;
    state.queryForm.total = res.resultData.total;
  }
}
getData();

/* 获取任务状态下拉菜单 */
async function getTaskStatusList() {
  const statusData = await taskManage.getTaskStatusList();
  if (statusData.resultData instanceof Array) {
    state.statusList = statusData.resultData;
  }
}
getTaskStatusList();

//查看任务备注
const isVisible = ref(false);
const remark = ref("");
async function openRecordRemark(data) {
  isVisible.value = true;
  remark.value = data.remark;
}

// 选中数据
const ids = ref([]);
const handleSelectionChange = (row) => {
  let res = row.map((item) => item.recordId);
  ids.value = res;
};

/* 批量删除 */
async function batchDel() {
  if (ids.value.length > 0) {
    ElMessageBox.confirm(
      t("801"),
      t("130"),
      {
        confirmButtonText: t("218"),
        cancelButtonText: t("217"),
      }
    )
      .then(() => {
        taskManage
          .deleteTaskRecord({
            ids: ids.value,
          })
          .then(() => {
            ElMessage({
              message: t("560"),
              type: "success",
            });
            if (
              state.queryForm.pageNo > 1 &&
              ids.value.length === state.queryForm.pageSize
            ) {
              state.queryForm.pageNo--;
            }
            getData();
          });
      })
      .catch(() => {
        ElMessage(t("549"));
      });
  } else {
    ElMessage(t("802"));
  }
}

//导出数据
const exportTaskRecord = async () => {
  if (deviceList.value.sn === undefined) {
    deviceList.value.sn = "";
  }
  if (taskSelectList.value.id === undefined) {
    taskSelectList.value.id = "";
  }
  if (ids.value.length == 0) {
    await download.downloadSource(
      `/api/v1/task/record/exportExcel`,
      {
        keyword: state.queryForm.keyword,
        sn: deviceList.value.sn,
        taskId: taskSelectList.value.id,
        taskStatus: state.queryForm.taskStatus,
        userId: memberList.value.id,
        pageNo: state.queryForm.pageNo,
        pageSize: state.queryForm.pageSize,
      },
      "application/vnd.ms-excel",
      "get"
    );
  } else {
    await download.downloadSource(
      `/api/v1/task/record/exportExcelById`,
      ids.value,
      "application/vnd.ms-excel",
      "post"
    );
  }
};

//跳转到执行记录
const router = useRouter();
function jumpExecRecord(recordId) {
  router.push({
    path: "/executeRecord",
    query: {
      recordId: recordId,
      taskId: route.query.taskId,
    },
  });
}

/* 打开图片详情 */
function picDetail(recordId) {
  console.log(recordId);
  state.picRecordId = recordId;
  state.isPicdetail = true;
}
</script>

<style lang='less' scoped>
/deep/ .el-message-box__btns {
  display: none;
}
.navRight {
  height: 6.5625rem;
  margin-left: 23.125rem;
  background: rgba(0, 28, 49, 0.8);
  padding: 1.0625rem 1.5625rem;

  .navRight_header {
    p {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #33ddf7;
    }

    .element_icon {
      font-size: 1.125rem;
    }
  }
  .navRight_footer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.9375rem;
    height: 40px;
  }
}
.container {
  border-top: 10px solid black;
  padding: 0 20px;
  height: calc(100% - 7.0625rem);
  background: rgba(0, 28, 49, 0.8);
}
.selection {
  background: transparent;
  border: none;
  color: #35e1fb;
  outline: 0;
  padding: 4px 5px;
  margin-left: 1rem;
  box-shadow: 0;
}
</style>